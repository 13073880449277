import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import Client from "../../../api-services/sap-contract/client";
import { style } from "../../../common/select-style/SelectStyle";

const CompanyStatement = ({
  data,
  handleChange,
  handleSAPChange,
  errorData,
  disabled,
  withBzr,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [contracts, setContracts] = useState([]);

  var client = new Client();

  useEffect(() => {
    setErrors(errorData);
  }, [errorData]);

  const getSAPContracts = async (value) => {
    if (value.length > 2) {
      let data = {
        filter: {
          contractNumberOrVendorContractNumber: value,
          isActive: true,
        },
        page: 1,
        pageSize: 10,
        fieldNamesForSort: [
          {
            sortDirection: 0,
            columnName: "ContractNumber"
          }
        ]
      };
      let response = await client.SearchSAPContracts(data, true);
      return response.results;
    }
  };

  const onSAPChange = (value) => {
    setContracts(value);
    handleSAPChange(value);
  };

  return (
    <>
      <h5>{withBzr ? t("CompanyDeclarationBZR") : t("CompanyDeclaration")}</h5>
      <br />
      <div className="row">
        <h5 className="light-header col-md-3">{t("BasicInformation")}</h5>
        {withBzr && (
          <>
            <h5 className="light-header col-md-3">{t("ResponsiblePerson")}</h5>
            <h5 className="light-header col-md-3">
              {t("ResponsiblePersonReplacement")}
            </h5>
            <h5 className="light-header col-md-3">{t("BZRResponsiblePerson")}</h5>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label>{t("ContractNumber")}</label>
            {disabled ? (
              <input
                type="text"
                className="form-control"
                value={data.contractNumber}
                disabled
              />
            ) : (
              <AsyncSelect
                cacheOptions
                defaultOptions
                styles={style}
                value={contracts}
                getOptionLabel={(e) => e.contractNumber + " (" + e.contractingParty + ")"}
                getOptionValue={(e) => e.id}
                loadOptions={getSAPContracts}
                onChange={onSAPChange}
                placeholder="Potrebno je uneti minimum 3 karaktera..."
                isClearable
              />
            )}
            {errors?.contractNumber && (
              <div className="text-danger">{errors.contractNumber}</div>
            )}
          </div>
          <div className="form-group">
            <label>{t("ExecutorName")}</label>
            <input
              type="text"
              className="form-control"
              disabled={disabled}
              value={data.companyName}
              onChange={(e) => handleChange("companyName", e.target.value)}
            />
            {errors?.companyName && (
              <div className="text-danger">{errors.companyName}</div>
            )}
          </div>
          <div className="form-group">
            <label>{t("ExecutorAddress")}</label>
            <input
              type="text"
              className="form-control"
              disabled={disabled}
              value={data.companyAddressAndCity}
              onChange={(e) =>
                handleChange("companyAddressAndCity", e.target.value)
              }
            />
            {errors?.companyAddressAndCity && (
              <div className="text-danger">{errors.companyAddressAndCity}</div>
            )}
          </div>
          <div className="form-group">
            <label>{t("CaseNumber")}</label>
            <input
              type="text"
              className="form-control"
              disabled={disabled}
              value={data.subjectMatterOfTheContract}
              onChange={(e) =>
                handleChange("subjectMatterOfTheContract", e.target.value)
              }
            />
            {errors?.subjectMatterOfTheContract && (
              <div className="text-danger">
                {errors.subjectMatterOfTheContract}
              </div>
            )}
          </div>
        </div>
        {withBzr && (
          <>
            <div className="col-md-3">
              <div className="form-group">
                <label>{t("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.responsiblePersonFirstName}
                  onChange={(e) =>
                    handleChange("responsiblePersonFirstName", e.target.value)
                  }
                />
                {errors?.responsiblePersonFirstName && (
                  <div className="text-danger">
                    {errors.responsiblePersonFirstName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("LastName")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.responsiblePersonLastName}
                  onChange={(e) =>
                    handleChange("responsiblePersonLastName", e.target.value)
                  }
                />
                {errors?.responsiblePersonLastName && (
                  <div className="text-danger">
                    {errors.responsiblePersonLastName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("Email")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.responsiblePersonEmail}
                  onChange={(e) =>
                    handleChange("responsiblePersonEmail", e.target.value)
                  }
                />
                {errors?.responsiblePersonEmail && (
                  <div className="text-danger">{errors.responsiblePersonEmail}</div>
                )}
              </div>
              <div className="form-group">
                <label>{t("PhoneNumber")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.responsiblePersonPhoneNumber}
                  onChange={(e) =>
                    handleChange("responsiblePersonPhoneNumber", e.target.value)
                  }
                />
                {errors?.responsiblePersonPhoneNumber && (
                  <div className="text-danger">
                    {errors.responsiblePersonPhoneNumber}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>{t("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.replacementResponsiblePersonFirstName}
                  onChange={(e) =>
                    handleChange(
                      "replacementResponsiblePersonFirstName",
                      e.target.value
                    )
                  }
                />
                {errors?.replacementResponsiblePersonFirstName && (
                  <div className="text-danger">
                    {errors.replacementResponsiblePersonFirstName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("LastName")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.replacementResponsiblePersonLastName}
                  onChange={(e) =>
                    handleChange(
                      "replacementResponsiblePersonLastName",
                      e.target.value
                    )
                  }
                />
                {errors?.replacementResponsiblePersonLastName && (
                  <div className="text-danger">
                    {errors.replacementResponsiblePersonLastName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("Email")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.replacementResponsiblePersonEmail}
                  onChange={(e) =>
                    handleChange(
                      "replacementResponsiblePersonEmail",
                      e.target.value
                    )
                  }
                />
                {errors?.replacementResponsiblePersonEmail && (
                  <div className="text-danger">
                    {errors.replacementResponsiblePersonEmail}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("PhoneNumber")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.replacementResponsiblePersonPhoneNumber}
                  onChange={(e) =>
                    handleChange(
                      "replacementResponsiblePersonPhoneNumber",
                      e.target.value
                    )
                  }
                />
                {errors?.replacementResponsiblePersonPhoneNumber && (
                  <div className="text-danger">
                    {errors.replacementResponsiblePersonPhoneNumber}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>{t("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.bzrResponsiblePersonFirstName}
                  onChange={(e) =>
                    handleChange("bzrResponsiblePersonFirstName", e.target.value)
                  }
                />
                {errors?.bzrResponsiblePersonFirstName && (
                  <div className="text-danger">
                    {errors.bzrResponsiblePersonFirstName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("LastName")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.bzrResponsiblePersonLastName}
                  onChange={(e) =>
                    handleChange("bzrResponsiblePersonLastName", e.target.value)
                  }
                />
                {errors?.bzrResponsiblePersonLastName && (
                  <div className="text-danger">
                    {errors.bzrResponsiblePersonLastName}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("Email")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.bzrResponsiblePersonEmail}
                  onChange={(e) =>
                    handleChange("bzrResponsiblePersonEmail", e.target.value)
                  }
                />
                {errors?.bzrResponsiblePersonEmail && (
                  <div className="text-danger">
                    {errors.bzrResponsiblePersonEmail}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>{t("PhoneNumber")}</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={disabled}
                  value={data.bzrResponsiblePersonPhoneNumber}
                  onChange={(e) =>
                    handleChange(
                      "bzrResponsiblePersonPhoneNumber",
                      e.target.value
                    )
                  }
                />
                {errors?.bzrResponsiblePersonPhoneNumber && (
                  <div className="text-danger">
                    {errors.bzrResponsiblePersonPhoneNumber}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CompanyStatement;
