import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Table, {
  getPageNumber,
  getPageSizeNumber,
  getSortBy,
} from "../../../common/table/Table.component";
import SearchInput from "../search-input/searchInput.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Client from "../../../api-services/bzr-service/client";
import { format } from "date-fns";
import { InputSearchModel } from "../../../models/InputModels";
import InputStatus from "../../../common/input-status/InputStatus";

const UnprocessedInput = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableName = "Unprocessed_Input";
  const rememberFilter = true;
  const [reset, setReset] = useState(false);

  const [filterData, setFilterData] = useState(new InputSearchModel());
  const statusOptions = [
    {
      value: 1,
      label: "Novi",
    },
    {
      value: 2,
      label: "Čeka potpis",
    },
    {
      value: 3,
      label: "Čeka odobrenje",
    },
    {
      value: 6,
      label: "Nepotpun",
    },
    {
      value: 7,
      label: "Novi dopunjen",
    },
    {
      value: 8,
      label: "Neispravno potpisan",
    },
    {
      value: 9,
      label: "Ponovo potpisan",
    },
  ];

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t("RequestNumber"),
      accessor: "ledgerNumber",
    },
    {
      Header: t("Executor"),
      accessor: "companyName",
    },
    {
      Header: t("ContractNumber"),
      accessor: "contractNumber",
    },
    {
      Header: t("CreationDate"),
      accessor: "createdDateTimeOnUtc",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.createdDateTimeOnUtc
              ? format(
                  new Date(row.original.createdDateTimeOnUtc),
                  "dd.MM.yyyy"
                )
              : ""}
          </div>
        );
      },
    },
    {
      Header: t("Status"),
      accessor: "status",
      Cell: ({ value }) => <InputStatus value={value} />,
    },
    {
      accessor: "preview",
      className: "one-button-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-primary table-btn"
              title={t("PreviewInput")}
              onClick={() => {
                previewInput(row.original.id);
              }}
            >
              <FontAwesomeIcon icon={solid("search")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  var client = new Client();

  useEffect(() => {
    reset && setReset(false);
  }, [data]);

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    const storedObject = sessionStorage.getItem("UNPROCESSED_INPUT_FILTER");
    if (storedObject) {
      var parsedObject = JSON.parse(storedObject);
      setFilterData(parsedObject);
    }

    let filter = storedObject ? parsedObject : filterData;

    var response = await client.SearchInputs({
      filter: {
        ...filter,
        statuses:
          filter.statuses && filter.statuses.length > 0
            ? filter.statuses.map((x) => {
                return x.value;
              })
            : statusOptions.map((x) => {
                return x.value;
              }),
      },
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    sessionStorage.setItem(
      "UNPROCESSED_INPUT_FILTER",
      JSON.stringify(filterData)
    );
    const result = await fetchDataForPage(
      getPageNumber(tableName, rememberFilter) + 1,
      getPageSizeNumber(tableName, rememberFilter),
      getSortBy(tableName, rememberFilter, true)
    );
    setData(result);
  };

  const resetFilters = async () => {
    setReset(true);
    sessionStorage.removeItem("UNPROCESSED_INPUT_FILTER");
    let data = new InputSearchModel();
    setFilterData(data);
    var response = await client.SearchInputs({
      filter: {
        ...data,
        statuses: statusOptions.map((x) => {
          return x.value;
        }),
      },
    });
    setData({ dataList: response.results, count: response.totalCount });
  };

  const previewInput = async (id) => {
    navigate("/BZRService/CreateInput/" + id);
  };

  return (
    <>
      <SearchInput
        filterData={filterData}
        setFilterData={setFilterData}
        handleCallback={searchData}
        resetFilters={resetFilters}
        statusOptions={statusOptions}
      />
      <br />
      <div className="col-md-12">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            navigate("/BZRService/CreateInput");
          }}
        >
          {t("CreateInput")}
        </button>
      </div>
      <br />
      <Table
        columns={columns}
        data={data}
        tableName={tableName}
        rememberFilter={rememberFilter}
        fetchFunction={fetchDataForPage}
        serverSide={true}
        setData={setData}
        hiddenColumn={"id"}
        resetFilters={reset}
      />
    </>
  );
};

export default UnprocessedInput;
