import { dateWithoutTimezone } from "../common/custom-date-picker/CustomDatePicker.component";

class Contract {
  constructor(
    id = 0,
    sapIdentifier = "",
    contractNumber = "",
    contractingParty = "",
    vendorContractNumber = "",
    address = "",
    email = "",
    validityPeriodDateFrom = dateWithoutTimezone(new Date()),
    validityPeriodDateTo = dateWithoutTimezone(new Date()),
    warnityPeriodDateFrom = dateWithoutTimezone(new Date()),
    warnityPeriodDateTo = dateWithoutTimezone(new Date()),
    authorizedPersons = []
  ) {
    this.id = id;
    this.sapIdentifier = sapIdentifier;
    this.contractNumber = contractNumber;
    this.contractingParty = contractingParty;
    this.vendorContractNumber = vendorContractNumber;
    this.address = address;
    this.email = email;
    this.validityPeriodDateFrom = validityPeriodDateFrom;
    this.validityPeriodDateTo = validityPeriodDateTo;
    this.warnityPeriodDateFrom = warnityPeriodDateFrom;
    this.warnityPeriodDateTo = warnityPeriodDateTo;
    this.authorizedPersons = authorizedPersons;
  }
}

export { Contract };
