import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Table from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import CreateInputEquipment from "../create-input-equipment/createInputEquipment.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputEquipmentModel } from "../../../models/InputModels";

const InputEquipments = ({ handleAddEquipment, equipments, disabled }) => {
  const { t } = useTranslation();
  const tableName = "Input_Equipments";

  const [edit, setEdit] = useState(false);
  const [equipmentIndex, setEquipmentIndex] = useState(-1);
  const [equipmentData, setEquipmentData] = useState(new InputEquipmentModel());
  const [errors, setErrors] = useState({});

  const columns = [
    {
      Header: t("EquipmentName"),
      accessor: "name",
    },
    {
      Header: t("Model"),
      accessor: "model",
    },
    {
      Header: t("Type"),
      accessor: "type",
    },
    {
      Header: t("IdentificationNumber"),
      accessor: "identificationNumber",
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangeEquipment")}
              onClick={() => {
                setEdit(true);
                setEquipmentIndex(row.index);
                setEquipmentData(row.original);
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title={t("DeleteEquipment")}
              onClick={() => removeEquipment(row.index)}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (equipments) {
      setData(equipments);
    }
  }, [equipments]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const addEquipment = (equipment) => {
    if (edit) {
      let dataCopy = data;
      dataCopy[equipmentIndex] = equipment;
      setData(dataCopy);
      handleAddEquipment(dataCopy);
    } else {
      setData([...data, equipment]);
      handleAddEquipment([...data, equipment]);
    }
  };

  const removeEquipment = (index) => {
    let newData = data.filter((v, i) => i !== index);
    setData(newData);
    handleAddEquipment(newData);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {data.length > 0 && (
        <>
          <h5>{t("InputEquipments")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={disabled && "edit"}
          />
        </>
      )}
      {!disabled && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setEdit(false);
            setEquipmentIndex(-1);
            setEquipmentData(new InputEquipmentModel());
            ShowDialog();
          }}
        >
          {t("AddInputEquipment")}
        </button>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={
          edit ? "Izmena mehanizacije i opreme za rad" : t("InputEquipments")
        }
      >
        <CreateInputEquipment
          withContract={true}
          addEquipment={addEquipment}
          equipmentData={equipmentData}
          isEdit={edit}
          inputEquipment={true}
          HideDialog={HideDialog}
          errors={errors}
          setErrors={setErrors}
        />
      </DialogModal>
    </div>
  );
};

export default InputEquipments;
