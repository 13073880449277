import { useTranslation } from "react-i18next";

const SearchCompany = ({ filter, setFilter, searchData, resetFilter }) => {
  const { t } = useTranslation();

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-4">
          <label>{t("CompanyName")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.companyName}
            onChange={(e) =>
              setFilter({ ...filter, companyName: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("CompanyAddress")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.address}
            onChange={(e) => setFilter({ ...filter, address: e.target.value })}
          />
        </div>
        <div className="form-group col-md-4">
          <label>{t("Email")}</label>
          <input
            type="text"
            className="form-control"
            value={filter.email}
            onChange={(e) => setFilter({ ...filter, email: e.target.value })}
          />
        </div>
        <div className="col-md-12 text-right">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              searchData();
            }}
          >
            {t("Search")}
          </button>
          <button
            type="button"
            className="btn btn-cancel"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              resetFilter();
            }}
          >
            {t("CancelSearch")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchCompany;
