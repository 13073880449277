import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";
import LicenceForm from "../../../components/external-executor-licences/licence-form/LicenceForm.component";
import { Licence } from "../../../models/LicenceModel";
import Client from "../../../api-services/licences/client";
import SearchObjects from "../../employee-licences/search-objects/searchObjects.component";
import SearchGroupObjects from "../search-group-objects/searchGroupObjects.component";
import SAPContract from "../../../components/bzr-service/sap-contract/sapContract.component";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import SearchPersons from "../../../components/external-executor-licences/search-persons/searchPersons.component";
import SearchEquipments from "../../../components/external-executor-licences/search-equipments/searchEquipments.component";
import { useNavigate, useLocation } from "react-router-dom";
import { MainContainer } from "../../../common/layout/Layout.style";
import AdditionalLicences from "../additional-licences/additionalLicences.component";
import Attachments from "../attachments/attachments.component";
import MultipleFiles from "../../../common/multiple-files/multipleFiles.component";
import { formElements } from "../../../common/licence-form-elements/FormElements";
import Joi from "joi-browser";
import {
  validateForm,
  handleSave,
} from "../../../common/validation/validation";
import CreatePersons from "../create-persons/CreatePersons.component";
import { style } from "../../../common/select-style/SelectStyle";
import DialogModal from "../../../common/modal/Modal.component";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const CreateLicence = ({ retroactive }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { isGroup } = useParams();

  const [licence, setLicence] = useState(new Licence());
  const [licenceTypeOpts, setLicenceTypeOpts] = useState([]);
  const [licenceType, setLicenceType] = useState(null);
  const [licenceTypeTitle, setLicenceTypeTitle] = useState("");
  const [persons, setPersons] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsPreview, setAttachmentsPreview] = useState([]);
  const [additionalLicence, setAdditionalLicence] = useState(null);
  const [withContract, setWithContract] = useState(true);
  const [contractError, setContractError] = useState(false);
  const [personsError, setPersonsError] = useState(false);
  const typesWithContract = [1, 3, 5, 7];
  const [dontShow, setDontShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [show, setShow] = useState(false);

  var showCookie = Cookies.get("dontShowNotification");

  const [errors, setErrors] = useState({});
  const [schema, setSchema] = useState({});

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  useEffect(() => {
    if (state) {
      setLicence(state.data);
    }
    getLicenceTypes();
  }, []);

  useEffect(() => {
    setLicence(new Licence());
  }, [isGroup]);

  useEffect(() => {
    formElements.forEach((x) => {
      if (x.required == "all" || x.required.includes(licenceType)) {
        if (x.elementType == "input") {
          schema[x.elementName] = Joi.string().required();
        } else if (x.elementType == "date") {
          schema[x.elementName] = Joi.date().required();
        }
      } else {
        schema[x.elementName] = Joi.optional();
      }
    });
    if (!typesWithContract.includes(licenceType)) {
      setLicence({ ...licence, fkSapcontract: null });
      setWithContract(false);
    } else {
      setWithContract(true);
    }
  }, [licenceType]);

  useEffect(() => {
    setLicence({ ...licence, numberOfPersons: persons.length });
    if (persons.length > 0) {
      setPersonsError(false);
    }
  }, [persons]);

  const getLicenceTypes = async () => {
    let response = await client.GetAllLicenceTypes();
    let res = response.map((x) => {
      return { value: x.id, label: x.name, type: x.documentType };
    });
    setLicenceTypeOpts(res);
  };

  const handleChange = (contract) => {
    let contractDate = new Date(contract.validityPeriodDateTo).setHours(0, 0, 0, 0);
    let warnityDate = new Date(contract.warnityPeriodDateTo).setHours(0, 0, 0, 0);
    let validityDate = warnityDate >= contractDate ? warnityDate : contractDate;

    // Postavite vreme na 23:59:59 za validityDate
    validityDate = new Date(validityDate);
    validityDate.setHours(23, 59, 59, 999);

    setLicence({
      ...licence,
      fkSapcontract: contract.id,
      // licenceValidityDateFrom: contract.validityPeriodDateFrom,
      licenceValidityDateTo: validityDate,
    });
    setContractError(false);
  };

  const handlePropertyChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, licence, errors, schema, t);
    setLicence(result.data);
    setErrors(result.errors);
  };

  const checkValidation = () => {
    const errorsResult = validateForm(licence, schema, t);
    setErrors(errorsResult);

    let errorContract = withContract && !licence.fkSapcontract;
    setContractError(errorContract);

    let errorPersons = persons.length < 1;
    setPersonsError(errorPersons);

    if (!errorsResult && !errorContract && !errorPersons) {
      if (showCookie) {
        createLicence();
      } else {
        setShowNotification(false);
        ShowDialog();
      }
    }
  }

  const createLicence = async () => {
    let data = {
      ...licence,
      licenceWorkflowPeople: persons,
      licenceWorkflowEquipments: equipments,
      licenceWorfklowDocuments: [],
      fkAdditionalLicenceNavigation: retroactive ? additionalLicence : null,
    };

    var formData = new FormData();
    serializeObjectIntoFormData(formData, data);

    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].guid)
        formData.append(
          "licenceWorkflowDocuments[" + i + "].guid",
          attachments[i].guid
        );
      else
        formData.append(
          "licenceWorkflowDocuments[" + i + "].file",
          attachments[i]
        );

      formData.append(
        "licenceWorkflowDocuments[" + i + "].name",
        attachments[i].name
      );
      formData.append("licenceWorkflowDocuments[" + i + "].documentType", 1);
    }

    if (retroactive) {
      let response = await client.CreateRetroactiveLicence(formData);
      if (response !== "Error") {
        navigate("/ExternalExecutorLicences");
      }
    } else {
      let response = await client.CreateFirstStep(formData);
      if (response !== "Error") {
        navigate("/ExternalExecutorLicences/ProcessLicence");
      }
    }
  };

  const closeDialog = () => {
    if (!showCookie && !showNotification) {
      createLicence();
    }
    if (dontShow) {
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 3);
      Cookies.set('dontShowNotification', true, { expires: expiryDate });
    }
    HideDialog();
  }

  return (
    <>
      <MainContainer>
        <h4>Kreiranje {retroactive ? "nove dozvole" : "novog zahteva"}</h4>
        <br />
        <div className="form-group">
          <label>{t("Foreigner")} &nbsp;</label>
          <input
            type="checkbox"
            value={licence.foreigner}
            checked={licence.foreigner}
            onChange={() => {
              setLicence({ ...licence, foreigner: !licence.foreigner });
            }}
          />
        </div>
        {isGroup == "true" ? (
          <SearchGroupObjects licence={licence} setLicence={setLicence} />
        ) : (
          <SearchObjects licence={licence} setLicence={setLicence} />
        )}
        {licence.objectIds?.length > (isGroup == "true" ? 1 : 0) && (
          <>
            <div className="row">
              <div className="form-group col-md-4">
                <label>Tip zahteva</label>
                <div title={licenceTypeTitle}>
                  <Select
                    placeholder={t("SelectFromList")}
                    options={licenceTypeOpts}
                    onChange={(e) => {
                      setLicence({ ...licence, fkLicenceType: e.value });
                      setLicenceType(e.type);
                      setLicenceTypeTitle(e.label);
                    }}
                    menuPortalTarget={document.body}
                    styles={style}
                    value={licenceTypeOpts.filter(
                      (x) => x.value == licence.fkLicenceType
                    )}
                  />
                </div>
              </div>
            </div>
            {licence.fkLicenceType && (
              <>
                <label>
                  <FontAwesomeIcon
                    onClick={() => {
                      setShowNotification(true);
                      ShowDialog();
                    }}
                    className="text-color fa-lg"
                    icon={solid("circle-info")}
                  />&nbsp;
                  {t("Notification")}
                </label>
                <LicenceForm
                  model={licence}
                  setModel={setLicence}
                  mode={"create"}
                  licenceType={licenceType}
                  errors={errors}
                  handleChange={handlePropertyChange}
                />
                <br />
                <div className="col-md-6">
                  <label>Prilozi</label>
                  <MultipleFiles
                    fileList={attachments}
                    setFileList={setAttachments}
                    accept={".pdf"}
                    name={"attachments"}
                  />
                </div>
                <br />
                {withContract && (
                  <>
                    <SAPContract
                      fkSapcontract={licence.fkSapcontract}
                      handleChange={handleChange}
                      canChange={true}
                      contractData={null}
                      contractNumber={""}
                    />
                    {contractError && (
                      <div className="text-danger">Ugovor nije izabran.</div>
                    )}
                    <br />
                    <br />
                  </>
                )}
                {licenceType == 9 ? (
                  <>
                    <CreatePersons
                      persons={persons}
                      setPersons={setPersons}
                      foreigner={licence.foreigner}
                    />
                    {personsError && (
                      <div className="text-danger">
                        {t("AtLeastOnePersonError")}
                      </div>
                    )}
                  </>
                ) : (
                  ((withContract && licence.fkSapcontract) ||
                    !withContract) && (
                    <>
                      <SearchPersons
                        sapContract={licence.fkSapcontract}
                        edit={true}
                        personsData={persons}
                        setPersonData={setPersons}
                        withContract={withContract}
                        foreigner={licence.foreigner}
                      />
                      {personsError && (
                        <div className="text-danger">
                          {t("AtLeastOnePersonError")}
                        </div>
                      )}
                      <br />
                      <SearchEquipments
                        sapContract={licence.fkSapcontract}
                        edit={true}
                        equipmentsData={equipments}
                        setEquipmentsData={setEquipments}
                        withContract={withContract}
                      />
                    </>
                  )
                )}
              </>
            )}
          </>
        )}
        <br />
        {licence.fkLicenceType && (
          <div className="col-md-12 clearfix">
            <button
              type="button"
              className="btn btn-accept pull-right"
              onClick={checkValidation}
            >
              {retroactive ? "Kreiraj dozvolu" : "Podnesi zahtev"}
            </button>
          </div>
        )}
      </MainContainer>
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={t("Notification")}
      >
        <div>{t("TimeLimitNotification")}</div>
        <ul>
          <li>{t("StockroomNotification")}</li>
          <li>{t("OtherPeopleNotification")}</li>
          <li>{t("ForeignersNotification")}</li>
        </ul>
        <br />
        {!showCookie && !showNotification &&
          <div className="form-group">
            <input
              type="checkbox"
              value={dontShow}
              checked={dontShow}
              onChange={() => setDontShow(!dontShow)}
            />
            <span><b>{t("DontShowAnymore")}</b> {t("NotificationInfo")}</span>
          </div>
        }
        <button
          type="button"
          className="btn btn-primary pull-right"
          onClick={closeDialog}
        >
          {t("Close")}
        </button>
      </DialogModal>
    </>
  );
};

export default CreateLicence;
