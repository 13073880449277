import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import Client from "../../../api-services/admin-panel/client";
import SearchCompany from "../search-company/searchCompany.component";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";

const Company = ({ user, handleChange, edit }) => {
  const { t } = useTranslation();
  const tableName = "Companies";

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    companyName: "",
    address: "",
    email: "",
  });
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [chosenCompany, setChosenCompany] = useState(null);
  const [show, setShow] = useState(false);

  const columns = [
    {
      Header: "companyIdentifier",
      accessor: "companyIdentifier",
    },
    {
      Header: t("CompanyName"),
      accessor: "companyName",
    },
    {
      Header: t("CompanyAddress"),
      accessor: "address",
    },
    {
      Header: t("Email"),
      accessor: "email",
    },
  ];

  var client = new Client();

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  useEffect(() => {
    if (
      user.companyIdentifier &&
      user.companyIdentifier != data[0]?.companyIdentifier
    ) {
      getCompany(user.companyIdentifier);
    }
  }, [user]);

  const getCompany = async (id) => {
    let response = await client.GetCompany(id);
    setData([response]);
  };

  const chooseCompany = () => {
    let chosen = companies.dataList.filter(
      (x) => x.companyIdentifier === chosenCompany
    );
    setData(chosen);
    handleChange("companyIdentifier", chosenCompany);
    HideDialog();
  };

  const fetchDataForPage = async (pageNumber, pageSize, sortBy) => {
    var response = await client.SearchCompanies({
      filter: filter,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });

    return { dataList: response.results, count: response.totalCount };
  };

  const searchData = async () => {
    const result = await fetchDataForPage(pageNumber, pageSize);
    setCompanies(result);
  };

  const resetFilter = async () => {
    let data = {
      companyName: "",
      address: "",
      email: "",
    };
    setFilter(data);
    var response = await client.SearchCompanies({
      filter: data,
      pageSize: pageSize,
      page: pageNumber,
      fieldNamesForSort: sortBy,
    });
    setCompanies({ dataList: response.results, count: response.totalCount });
  };

  const handleTableRowClick = useCallback((response) => {
    setChosenCompany(response.companyIdentifier);
  });

  return (
    <>
      {data.length > 0 && (
        <Table
          columns={columns}
          data={data}
          tableName={tableName}
          rememberFilter={false}
          hiddenColumn={"companyIdentifier"}
        />
      )}
      <button
        type="button"
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
        onClick={ShowDialog}
      >
        {t("ChooseCompany")}
      </button>
      <DialogModal show={show} onHide={HideDialog} title={t("Company")}>
        <>
          <SearchCompany
            filter={filter}
            setFilter={setFilter}
            searchData={searchData}
            resetFilter={resetFilter}
          />
          <br />
          <Table
            columns={columns}
            data={companies}
            tableName={tableName}
            rememberFilter={true}
            fetchFunction={fetchDataForPage}
            serverSide={true}
            setData={setCompanies}
            hiddenColumn={"companyIdentifier"}
            handleClickOnRow={handleTableRowClick}
          />
          {edit ?
            <ButtonWithConfirmation
              className="btn btn-accept pull-right"
              onClick={chooseCompany}
              contentText={t("CompanyChangeWarning")}
              handleYesText={t("Accept")}
            >
              {t("Choose")}
            </ButtonWithConfirmation>
            :
            <button
              type="button"
              className="btn btn-accept pull-right"
              onClick={chooseCompany}
            >
              {t("Choose")}
            </button>
          }
        </>
      </DialogModal>
    </>
  );
};

export default Company;
