export const BZRService = [
    {
        buttonName: "Pregled i obrada unosa",
        path: "/BZRService",
        role: ["BzrOfficer"]
    },
    {
        buttonName: "BZR lica",
        path: "/BZRService/BZRPersons",
        role: ["BzrOfficer"]
    },
    {
        buttonName: "BZR oprema",
        path: "/BZRService/BZREquipments",
        role: ["BzrOfficer"]
    }
]

export const Contracts = [
    {
        buttonName: "Pretraga i pregled ugovora",
        path: "/Contracts",
        role: ["BzrOfficer", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantExternalExecutors", "ApplicantRetroactiveIssuanceOfLicenses"]
    }
]

export const EELicences = [
    {
        buttonName: "Kreiranje zahteva",
        links: [
            {
                title: "Zahtev za jedan objekat",
                path: "/ExternalExecutorLicences/CreateLicence/" + false,
                role: ["ApplicantExternalExecutors"]
            },
            {
                title: "Zahtev za više objekata",
                path: "/ExternalExecutorLicences/CreateLicence/" + true,
                role: ["ApplicantExternalExecutors"]
            },
        ],
        role: ["ApplicantExternalExecutors"]
    },
    {
        buttonName: "Retroaktivno kreiranje dozvole",
        path: "/ExternalExecutorLicences/CreateLicenceRetroactive",
        role: ["ApplicantRetroactiveIssuanceOfLicenses"]
    },
    {
        buttonName: "Pregled i obrada zahteva",
        path: "/ExternalExecutorLicences/ProcessLicence",
        role: ["ApplicantExternalExecutors", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "DefenceAndSecurityOfficer"]
    },
    {
        buttonName: "Pregled dozvola",
        path: "/ExternalExecutorLicences",
        role: ["ApplicantExternalExecutors", "AuthorizedPersonForSecondLevelObjects", "AuthorizedPersonForThirdLevelObjects", "ApplicantRetroactiveIssuanceOfLicenses", "DefenceAndSecurityOfficer"]
    }
]

export const EmployeeLicences = [
    {
        buttonName: "Kreiranje dozvole",
        path: "/EmployeeLicences/CreateLicenceForEmployee",
        role: ["ApplicantOutOfWorkingTimeLicence"]
    },
    {
        buttonName: "Pregled dozvola/zahteva",
        path: "/EmployeeLicences",
        role: ["ApplicantOutOfWorkingTimeLicence"]
    },
    {
        buttonName: "Podnošenje zahteva",
        path: "/EmployeeLicences/MyLicences/CreateLicenceForYourself",
        role: ["ApplicantOutOfWorkingTimeLicence", "ApplicantEmployee"]
    },
    {
        buttonName: "Moje dozvole/zahtevi",
        path: "/EmployeeLicences/MyLicences",
        role: ["ApplicantOutOfWorkingTimeLicence", "ApplicantEmployee"]
    }
]

export const AdminPanel = [
    {
        buttonName: "Šifarnici",
        path: "/AdminPanel",
        role: ["Administrator"]
    },
    {
        buttonName: "Eksterni korisnici",
        path: "/AdminPanel/ExternalUsers",
        role: ["Administrator", "AdministratorOfExternalExecutorAccounts"]
    },
    {
        buttonName: "Interni korisnici",
        path: "/AdminPanel/InternalUsers",
        role: ["Administrator"]
    }
]