import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Table from "../../../common/table/Table.component";
import DialogModal from "../../../common/modal/Modal.component";
import CreateInputPerson from "../../../components/bzr-service/create-input-person/createInputPerson.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InputPersonModel } from "../../../models/InputModels";

const InputPersons = ({ handleAddPerson, persons, withBzr, disabled }) => {
  const { t } = useTranslation();
  const tableName = "Input_Persons";

  const [edit, setEdit] = useState(false);
  const [personIndex, setPersonIndex] = useState(-1);
  const [personData, setPersonData] = useState(new InputPersonModel());
  const [errors, setErrors] = useState({});
  const [personIdError, setPersonIdError] = useState(false);
  const [passwordNumberError, setPasswordNumberError] = useState(false);

  const columns = [
    {
      Header: t("Name"),
      accessor: "firstName",
    },
    {
      Header: t("LastName"),
      accessor: "lastName",
    },
    {
      Header: t("Workplace"),
      accessor: "employeePosition",
    },
    {
      Header: t("IdentityCardNumber"),
      accessor: "identityCardNumber",
    },
    {
      Header: t("IdentityCardPlace"),
      accessor: "identityCardPlace",
    },
    {
      Header: t("PassportNumber"),
      accessor: "identityPassportNumber",
    },
    {
      Header: t("PassportPlace"),
      accessor: "identityPassportPlace",
    },
    {
      Header: t("Foreigner"),
      accessor: "foreigner",
      Cell: ({ row }) => {
        return <>{row.original.foreigner ? t("Yes") : t("No")}</>;
      },
    },
    {
      accessor: "edit",
      className: "two-buttons-column",
      Cell: ({ row }) => {
        return (
          <>
            <button
              type="button"
              className="btn btn-incomplete table-btn"
              title={t("ChangePerson")}
              onClick={() => {
                setEdit(true);
                setPersonIndex(row.index);
                setPersonData(row.original);
                ShowDialog();
              }}
            >
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
            <button
              type="button"
              className="btn btn-danger table-btn"
              title={t("DeletePerson")}
              onClick={() => removePerson(row.index)}
            >
              <FontAwesomeIcon icon={solid("trash")} />
            </button>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (persons) {
      setData(persons);
    }
  }, [persons]);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);

  const addPerson = (person) => {
    let index = edit ? personIndex : -1;
    let personIdExist = person.foreigner
      ? false
      : data.filter(
          (x, i) =>
            i != index &&
            x.identityCardNumber == person.identityCardNumber &&
            person.identityCardNumber != ""
        ).length > 0;
   
    const passwordNumberExist = !person.foreigner
      ? false
      : data.filter(
          (x, i) =>
            i !== index &&
            x.identityPassportNumber === person.identityPassportNumber &&
            person.identityPassportNumber !== ""
        ).length > 0;

    if (personIdExist) {
      setPersonIdError(true);
    }
    else if(passwordNumberExist){
      setPasswordNumberError(true);
    }
     else {
      setPersonIdError(false);
      setPasswordNumberError(false);
      HideDialog();
      if (edit) {
        let dataCopy = data;
        dataCopy[personIndex] = person;
        setData(dataCopy);
        handleAddPerson(dataCopy);
      } else {
        setData([...data, person]);
        handleAddPerson([...data, person]);
      }
    }
  };

  const removePerson = (index) => {
    let newData = data.filter((v, i) => i !== index);
    setData(newData);
    handleAddPerson(newData);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      {data.length > 0 && (
        <>
          <h5>{t("InputPersons")}</h5>
          <Table
            columns={columns}
            data={data}
            tableName={tableName}
            rememberFilter={false}
            hiddenColumn={disabled && "edit"}
          />
        </>
      )}
      {!disabled && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setEdit(false);
            setPersonIndex(-1);
            setPersonData({
              ...new InputPersonModel(),
              bzrWithContract: withBzr
            });
            ShowDialog();
          }}
        >
          {t("AddInputPerson")}
        </button>
      )}
      <DialogModal
        show={show}
        onHide={HideDialog}
        title={edit ? "Izmena zaposlenog" : t("InputPerson")}
      >
        <CreateInputPerson
          withContract={true}
          addPerson={addPerson}
          personData={personData}
          isEdit={edit}
          inputPerson={true}
          errors={errors}
          setErrors={setErrors}
          personIdError={personIdError}
          setPersonIdError={setPersonIdError}
          passwordNumberError={passwordNumberError}
          setPasswordNumberError={setPasswordNumberError}
        />
      </DialogModal>
    </div>
  );
};

export default InputPersons;
