import { useState, useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import Persons from "../../../components/bzr-service/persons/persons.component";
import { MainContainer } from "../../../common/layout/Layout.style";

const BZRPersons = () => {
  const [page, setPage] = useState("1");

  useEffect(() => {
    let activePage = sessionStorage.getItem("PERSONS_ACTIVE_PAGE");
    if (activePage) {
      setPage(activePage);
    }
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
    sessionStorage.setItem("PERSONS_ACTIVE_PAGE", value);
  };

  return (
    <MainContainer>
      <TabContext value={page}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="tabList"
        >
          <TabList onChange={handleChangePage}>
            <Tab label="Lica sa ugovorom u sap-u" value="1" className="tab" />
            <Tab label="Lica bez ugovora u sap-u" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Persons withContract={true} key={true} />
        </TabPanel>
        <TabPanel value="2">
          <Persons withContract={false} key={false} />
        </TabPanel>
      </TabContext>
    </MainContainer>
  );
};

export default BZRPersons;
