import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./common/layout/Layout.component";
import Login from "./common/pages/login/Login";
import PublicRoute from "./common/route-and-role/publicRoute";
import ProtectedRoute from "./common/route-and-role/protectedRoute";
import NotAllowed from "./common/pages/not-allowed/NotAllowed";

import Index from "./pages/index/index";

import InputReview from "./pages/bzr-service/input-review/inputReview";
import CreateInput from "./pages/bzr-service/create-input/createInput";
import BZRPersons from "./pages/bzr-service/bzr-persons/bzrPersons";
import BZREquipments from "./pages/bzr-service/bzr-equipments/bzrEquipments";

import Contracts from "./pages/contracts/contracts";
import PreviewContract from "./components/contracts/preview-contract/previewContract.component";

import EELicences from "./pages/external-executor-licences/licences-preview/externalExecutorLicences";
import CreateLicence from "./components/external-executor-licences/create-licence/CreateLicence.component";
import ProcessLicence from "./pages/external-executor-licences/process-licence/processLicence";
import PreviewLicence from "./pages/external-executor-licences/preview-licence/previewLicence";
import CreateLicenceRenewal from "./pages/external-executor-licences/create-licence/create-licence-renewal/createLicenceRenewal";
import GeneralPreview from "./pages/external-executor-licences/preview-licence/generalPreview";

import EmployeeLicences from "./pages/employee-licences/employee-licences-preview/employeeLicences";
import CreateLicenceForEmployee from "./pages/employee-licences/create-licence-for-employee/createLicenceForEmployee";
import PreviewLicenceForEmployee from "./pages/employee-licences/preview-licence-for-employee/previewLicenceForEmployee";
import MyLicences from "./pages/employee-licences/my-licences-preview/myLicences";
import CreateLicenceForYourself from "./pages/employee-licences/create-licence-for-yourself/createLicenceForYourself";
import PreviewLicenceForYourself from "./pages/employee-licences/preview-licence-for-yourself/previewLicenceForYourself";

import Codebooks from "./pages/admin-panel/codebooks/codebooks";
import CreateCodebook from "./pages/admin-panel/create-codebook/createCodebook";
import ExternalUsers from "./pages/admin-panel/external-users/externalUsers";
import InternalUsers from "./pages/admin-panel/internal-users/internalUsers";
import CreateUser from "./pages/admin-panel/create-user/createUser";
import EditInternalUser from "./pages/admin-panel/edit-internal-user/editInternalUser";

import CreatePerson from "./pages/bzr-service/create-person/createPerson.component";
import CreateEquipment from "./pages/bzr-service/create-equipment/createEquipment.component";

import Profile from "./pages/profile/profile";

function App() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      ></Route>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Index />} />
        <Route path="/BZRService" element={<InputReview />} />
        <Route path="/BZRService/CreateInput/:id?" element={<CreateInput />} />
        <Route
          path="/BZRService/BZRPersons"
          element={<BZRPersons />}
        />
        <Route
          path="/BZRService/BZREquipments"
          element={<BZREquipments />}
        />
        <Route path="/BZRService/CreatePerson/:contract?/:id?" element={<CreatePerson />} />
        <Route
          path="/BZRService/CreateEquipment/:contract?/:id?"
          element={<CreateEquipment />}
        />
        <Route path="/Contracts" element={<Contracts />} />
        <Route path="/Contracts/PreviewContract/:id" element={<PreviewContract />} />
        <Route path="/ExternalExecutorLicences" element={<EELicences />} />
        <Route
          path="/ExternalExecutorLicences/CreateLicence/:isGroup"
          element={<CreateLicence retroactive={false} key={false} />}
        />
        <Route
          path="/ExternalExecutorLicences/CreateLicenceRetroactive"
          element={<CreateLicence retroactive={true} key={true} />}
        />
        <Route
          path="/ExternalExecutorLicences/CreateLicenceRenewal"
          element={<CreateLicenceRenewal />}
        />
        <Route
          path="/ExternalExecutorLicences/GeneralPreview/:id"
          element={<GeneralPreview />}
        />
        <Route
          path="/ExternalExecutorLicences/ProcessLicence"
          element={<ProcessLicence />}
        />
        <Route
          path="/ExternalExecutorLicences/PreviewLicence/:id"
          element={<PreviewLicence />}
        />
        <Route path="/EmployeeLicences" element={<EmployeeLicences />} />
        <Route
          path="/EmployeeLicences/CreateLicenceForEmployee"
          element={<CreateLicenceForEmployee />}
        />
        <Route
          path="/EmployeeLicences/PreviewLicenceForEmployee/:id"
          element={<PreviewLicenceForEmployee />}
        />
        <Route path="/EmployeeLicences/MyLicences" element={<MyLicences />} />
        <Route
          path="/EmployeeLicences/MyLicences/CreateLicenceForYourself"
          element={<CreateLicenceForYourself />}
        />
        <Route
          path="/EmployeeLicences/MyLicences/PreviewLicenceForYourself/:id"
          element={<PreviewLicenceForYourself />}
        />
        <Route path="/AdminPanel" element={<Codebooks />} />
        <Route path="/AdminPanel/CreateCodebook/:id?" element={<CreateCodebook />} />
        <Route path="/AdminPanel/ExternalUsers" element={<ExternalUsers />} />
        <Route path="/AdminPanel/InternalUsers" element={<InternalUsers />} />
        <Route path="/AdminPanel/CreateUser/:id?" element={<CreateUser />} />
        <Route
          path="/AdminPanel/EditInternalUser/:id"
          element={<EditInternalUser />}
        />
        <Route path="/MyProfile" element={<Profile />} />
      </Route>
      <Route path="/" element={<Layout />}>
        <Route path="/notAllowed" element={<NotAllowed />} />
      </Route>
    </Routes>
  );
}

export default App;
