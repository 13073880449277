import CompanyStatement from "../../../components/bzr-service/company-statement/companyStatement.component";
import InputPersons from "../../../components/bzr-service/input-persons/inputPersons.component";
import InputEquipments from "../../../components/bzr-service/input-equipments/inputEquipments.component";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Client from "../../../api-services/bzr-service/client";
import {
  InputModel,
  InputAcceptRejectModel,
} from "../../../models/InputModels";
import {
  StatusForSecondStep,
  StatusForFourthStep,
} from "../../../common/input-status/InputStatus";
import MultipleFiles from "../../../common/multiple-files/multipleFiles.component";
import api from "../../../api";
import { env } from "../../../env";
import InputDocuments from "../../../components/bzr-service/input-documents/inputDocuments.component";
import SAPContract from "../../../components/bzr-service/sap-contract/sapContract.component";
import DialogModal from "../../../common/modal/Modal.component";
import History from "../../../components/history/history.component";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import { MainContainer } from "../../../common/layout/Layout.style";
import ButtonWithConfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import InputStatus from "../../../common/input-status/InputStatus";
import AlertDialog from "../../../common/alert-dialog/AlertDialog.component";
import Joi from "joi-browser";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";

var baseURL = env.REACT_APP_API_URL_AAMS;

const CreateInput = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [model, setModel] = useState(new InputModel());
  const [acceptRejectModel, setAcceptRejectModel] = useState(
    new InputAcceptRejectModel()
  );
  const [history, setHistory] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [reject, setReject] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [personsError, setPersonsError] = useState(false);

  const [firstAttachment, setFirstAttachment] = useState();
  const firstAttachmentRef = useRef(null);
  const [secondAttachment, setSecondAttachment] = useState();
  const secondAttachmentRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");
  const [showDoc, setShowDoc] = useState(false);
  const [showComm, setShowComm] = useState(false);
  const [rejectComment, setRejectComment] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showRejectAlert, setShowRejectAlert] = useState(false);

  const schema = {
    companyName: Joi.string().required(),
    companyAddressAndCity: Joi.string().required(),
    contractNumber: Joi.string().required(),
    subjectMatterOfTheContract: Joi.string().required(),
    responsiblePersonFirstName: Joi.optional(),
    responsiblePersonLastName: Joi.optional(),
    responsiblePersonEmail: Joi.optional(),
    responsiblePersonPhoneNumber: Joi.optional(),
    replacementResponsiblePersonFirstName: Joi.optional(),
    replacementResponsiblePersonLastName: Joi.optional(),
    replacementResponsiblePersonEmail: Joi.optional(),
    replacementResponsiblePersonPhoneNumber: Joi.optional(),
    bzrResponsiblePersonFirstName: Joi.optional(),
    bzrResponsiblePersonLastName: Joi.optional(),
    bzrResponsiblePersonPhoneNumber: Joi.optional(),
    bzrResponsiblePersonEmail: Joi.string().email().allow(""),
  };

  const rejectSchema = {
    comment: Joi.string().required()
  }

  var client = new Client();

  useEffect(() => {
    if (id) {
      getInput(id);
      setAcceptRejectModel({ ...acceptRejectModel, id: id });
    }
  }, []);

  const ShowDialog = () => setShow(true);
  const HideDialog = () => setShow(false);
  const ShowDocument = () => setShowDoc(true);
  const HideDocument = () => setShowDoc(false);
  const ShowComment = () => setShowComm(true);
  const HideComment = () => setShowComm(false);
  const ShowAlertDialog = () => setShowAlert(true);
  const HideAlertDialog = () => setShowAlert(false);
  const ShowRejectAlertDialog = () => setShowRejectAlert(true);
  const HideRejectAlertDialog = () => setShowRejectAlert(false);

  const getInput = async (id) => {
    let data = await client.GetInput(id);
    setDocuments(data.inputWorkflowDocuments);
    data.inputWorkflowDocuments = [];
    setModel(data);
    setHistory(data.inputWorkflowHistories);
    let last =
      data.inputWorkflowHistories[data.inputWorkflowHistories.length - 1];
    if (last.comment) {
      setRejectComment(last.comment);
      ShowComment();
    }
    setDisabled(true);
  };

  const handleChange = (prop, value) => {
    //setModel({ ...model, [prop]: value });
    const obj = { name: prop, value: value };
    const result = handleSave(obj, model, errors, schema, t);
    setModel(result.data);
    setErrors(result.errors);
  };

  const handleCommentChange = (prop, value) => {
    const obj = { name: prop, value: value };
    const result = handleSave(obj, acceptRejectModel, errors, rejectSchema, t);
    setAcceptRejectModel(result.data);
    setErrors(result.errors);
  };

  const handleSAPChange = (contract) => {
    const obj = {
      ...model,
      fkSapcontract: contract?.id,
      contractNumber: contract ? contract.contractNumber : "",
      companyName: contract ? contract.contractingParty : "",
      companyAddressAndCity: contract ? contract.address : "",
    };
    setModel(obj);

    if (contract) {
      setErrors({
        ...errors,
        contractNumber: null,
        companyName: null,
        companyAddressAndCity: null,
      });
    }
  };

  const handleAddPerson = (data) => {
    setModel({ ...model, inputWorkflowPeople: data });
    if (data.length > 0) {
      setPersonsError(false);
    } else {
      setPersonsError(true);
    }
  };

  const handleAddEquipment = (data) => {
    setModel({ ...model, inputWorkflowEquipments: data });
  };

  const incompleteSecondStep = async () => {
    let data = { ...acceptRejectModel, sapContractId: model.fkSapcontract };
    setAcceptRejectModel(data);
    let response = await client.IncompleteSecondStep(data);
    HandleResponse(response);
  };

  const incompleteFourthStep = async () => {
    let response = await client.IncompleteFourthStep(acceptRejectModel);
    HandleResponse(response);
  };

  const acceptSecondStep = async () => {
    let data = { ...acceptRejectModel, sapContractId: model.fkSapcontract };
    setAcceptRejectModel(data);
    let response = await client.AcceptSecondStep(data);
    HandleResponse(response);
  };

  const rejectSecondStep = async () => {
    let data = { ...acceptRejectModel, sapContractId: model.fkSapcontract };
    setAcceptRejectModel(data);
    let response = await client.RejectSecondStep(data);
    HandleResponse(response);
  };

  const acceptFourthStep = async () => {
    let data = {
      id: model.id,
    };
    var formData = new FormData();
    serializeObjectIntoFormData(formData, data);

    //Appending first attachment
    if (firstAttachment) {
      formData.append(
        "inputWorkflowDocuments[" + 0 + "].file",
        firstAttachment
      );
      formData.append(
        "inputWorkflowDocuments[" + 0 + "].name",
        firstAttachment.name
      );
      formData.append("inputWorkflowDocuments[" + 0 + "].documentType", 1);
    }

    //Appending second attachment
    if (secondAttachment) {
      formData.append(
        "inputWorkflowDocuments[" + 1 + "].file",
        secondAttachment
      );
      formData.append(
        "inputWorkflowDocuments[" + 1 + "].name",
        secondAttachment.name
      );
      formData.append("inputWorkflowDocuments[" + 1 + "].documentType", 2);
    }

    if (model.createdByBzrOfficer) {
      var response = await client.AcceptFourthStepBzr(formData);
    } else {
      var response = await client.AcceptFourthStep(formData);
    }
    HandleResponse(response);
  };

  const rejectFourthStep = async () => {
    let data = { ...acceptRejectModel, sapContractId: model.fkSapcontract };
    setAcceptRejectModel(data);
    if (model.createdByBzrOfficer) {
      var response = await client.RejectFourthStepBzr(data);
    } else {
      var response = await client.RejectFourthStep(data);
    }
    HandleResponse(response);
  };

  const checkValidation = () => {
    if (model.bzrWithContract) {
      schema.responsiblePersonFirstName = Joi.string().required();
      schema.responsiblePersonLastName = Joi.string().required();
      schema.responsiblePersonEmail = Joi.string().email().required();
      schema.responsiblePersonPhoneNumber = Joi.string().required();
      schema.replacementResponsiblePersonFirstName = Joi.string().required();
      schema.replacementResponsiblePersonLastName = Joi.string().required();
      schema.replacementResponsiblePersonEmail = Joi.string().email().required();
      schema.replacementResponsiblePersonPhoneNumber = Joi.string().required();
      schema.bzrResponsiblePersonFirstName = Joi.string().required();
      schema.bzrResponsiblePersonLastName = Joi.string().required();
      schema.bzrResponsiblePersonPhoneNumber = Joi.string().required();
      schema.bzrResponsiblePersonEmail = Joi.string().email().required();
    }
    const errorsResult = validateForm(model, schema, t);
    setErrors(errorsResult);

    let errorPersons = model.inputWorkflowPeople.length < 1;
    if (errorPersons) {
      setPersonsError(true);
    } else {
      setPersonsError(false);
    }
    if (!errorsResult && !errorPersons) {
      ShowAlertDialog();
    }
  };

  const checkCommentValidation = () => {
    const errorsResult = validateForm(acceptRejectModel, rejectSchema, t);
    setErrors(errorsResult);

    if (!errorsResult) {
      ShowRejectAlertDialog();
    }
  }

  const createInput = async () => {
    let response = await client.CreateInput(model);
    HandleResponse(response);
  };

  const HandleResponse = (response) => {
    if (response.code === 2) {
      let result = serverValidation(response.data, t);
      setErrors(result);
    } else {
      HideDialog();
      navigate("/BZRService");
    }
  };

  const getDocument = async (id, name) => {
    let response = await api.get(
      baseURL + "/InputWorkflow/GetDocument?id=" + id,
      {
        responseType: "blob",
      }
    );
    var blob = new Blob([response.data], { type: response.data.type });
    var url = window.URL.createObjectURL(blob);
    setDoc(url);
    setDocName(name);
    ShowDocument();
  };

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = doc;
    link.download = docName;
    link.click();
  };

  return (
    <>
      {history.length > 0 && <History history={history} />}
      {documents.length > 0 && (
        <MainContainer>
          <h5>Dokumentacija</h5>
          <div>
            <MultipleFiles
              fileList={documents}
              setFileList={setDocuments}
              accept={".pdf"}
              name={"documents"}
              edit={false}
              getDocument={getDocument}
              bzr={true}
            />
          </div>
        </MainContainer>
      )}
      <MainContainer>
        {id && (
          <>
            <h4>
              <span style={{ fontWeight: "400" }}>{t("RequestNumber")}: </span>
              {model.ledgerNumber}
            </h4>
            <h5>
              <span style={{ fontWeight: "400" }}>{t("Status")}: </span>
              <InputStatus value={model.status} />
            </h5>
            <br />
          </>
        )}
        <CompanyStatement
          data={model}
          handleChange={handleChange}
          handleSAPChange={handleSAPChange}
          errorData={errors}
          disabled={disabled}
          withBzr={model.bzrWithContract}
        />
        <br />
        {disabled ? (
          <label>
            Ugovor sa BZR sporazumom: {model.bzrWithContract ? t("Yes") : t("No")}
          </label>
        ) : (
          <div className="form-group">
            <label>Ugovor sa BZR sporazumom&nbsp;</label>
            <input
              type="checkbox"
              value={model.bzrWithContract}
              checked={model.bzrWithContract}
              onChange={() =>
                setModel({ ...model, bzrWithContract: !model.bzrWithContract })
              }
            />
          </div>
        )}
        <InputPersons
          handleAddPerson={handleAddPerson}
          persons={model.inputWorkflowPeople}
          withBzr={model.bzrWithContract}
          disabled={disabled}
        />
        {personsError && (
          <div className="text-danger">{t("AtLeastOnePersonError")}</div>
        )}
        <br />
        {model.bzrWithContract && (
          <>
            <InputEquipments
              handleAddEquipment={handleAddEquipment}
              equipments={model.inputWorkflowEquipments}
              disabled={disabled}
            />
            <br />
          </>
        )}
        <SAPContract
          fkSapcontract={model.fkSapcontract}
          handleChange={handleSAPChange}
          canChange={false}
          contractData={model.fkSapcontractNavigation}
          contractNumber={model.contractNumber}
        />
        <br />
        {StatusForSecondStep.includes(model.status) && (
          <div className="col-md-12 clearfix">
            {model.status === 0 ? (
              <button
                type="button"
                className="btn btn-accept pull-right m-2"
                onClick={checkValidation}
              >
                {t("SendInput")}
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-primary pull-right m-2"
                  onClick={() => {
                    setReject(true);
                    ShowDialog();
                  }}
                >
                  {t("Reject")}
                </button>
                <button
                  type="button"
                  className="btn btn-incomplete pull-right m-2"
                  onClick={() => {
                    setReject(false);
                    setIncomplete(true);
                    ShowDialog();
                  }}
                >
                  {t("Incomplete")}
                </button>
                <ButtonWithConfirmation
                  className="btn btn-accept pull-right m-2"
                  onClick={acceptSecondStep}
                  contentText={"Unos će biti odobren."}
                  handleYesText={t("Accept")}
                >
                  {t("Approve")}
                </ButtonWithConfirmation>
              </>
            )}
          </div>
        )}
        {StatusForFourthStep.includes(model.status) && (
          <>
            <InputDocuments
              documents={documents}
              firstAttachment={firstAttachment}
              setFirstAttachment={setFirstAttachment}
              firstAttachmentRef={firstAttachmentRef}
              secondAttachment={secondAttachment}
              setSecondAttachment={setSecondAttachment}
              secondAttachmentRef={secondAttachmentRef}
              bzrWithContract={model.bzrWithContract}
              bzrCreated={model.createdByBzrOfficer}
              inputId={model.id}
            />
            <div className="col-md-12 clearfix">
              <button
                type="button"
                className="btn btn-primary pull-right m-2"
                onClick={() => {
                  setReject(true);
                  ShowDialog();
                }}
              >
                {t("Reject")}
              </button>
              {!model.createdByBzrOfficer &&
                <button
                  type="button"
                  className="btn btn-incomplete pull-right m-2"
                  onClick={() => {
                    setReject(false);
                    ShowDialog();
                  }}
                >
                  {t("IncorrectlySigned")}
                </button>
              }
              <ButtonWithConfirmation
                className="btn btn-accept pull-right m-2"
                onClick={acceptFourthStep}
                contentText={"Unos će biti odobren."}
                handleYesText={t("Accept")}
                disabled={!firstAttachment || (model.bzrWithContract && model.createdByBzrOfficer && !secondAttachment)}
              >
                {t("Approve")}
              </ButtonWithConfirmation>
            </div>
          </>
        )}
        <DialogModal show={show} onHide={HideDialog} title="Završi korak">
          <div className="form-group">
            <label>{t("Comment")}</label>
            <textarea
              className="form-control"
              value={acceptRejectModel.comment}
              onChange={(e) =>
                handleCommentChange("comment", e.target.value)
              }
            ></textarea>
            {errors?.comment && (
              <div className="text-danger">
                {errors.comment}
              </div>
            )}
          </div>
          <button
            type="button"
            className={"btn " + (reject ? "btn-primary" : "btn-incomplete")}
            onClick={checkCommentValidation}
          >
            {reject ? t("Reject") : incomplete ? t("Incomplete") : t("IncorrectlySigned")}
          </button>
        </DialogModal>
        <DialogModal
          show={showDoc}
          onHide={HideDocument}
          title={docName}
          download={true}
          downloadDocument={downloadDocument}
        >
          <div>
            <iframe src={doc} style={{ width: "100%", height: "800px" }} />
          </div>
        </DialogModal>
        <DialogModal
          show={showComm}
          onHide={HideComment}
          title={<InputStatus value={model.status} />}
        >
          <label>Komentar vraćanja/odbijanja: </label>
          <div>{rejectComment}</div>
        </DialogModal>
        {showAlert && (
          <AlertDialog
            handleYes={() => {
              HideAlertDialog();
              createInput();
            }}
            handleNo={() => {
              HideAlertDialog();
            }}
            show={showAlert}
            contentText={"Unos će biti kreiran."}
            handleYesText={t("Accept")}
            className={"btn btn-accept"}
          ></AlertDialog>
        )}
        {showRejectAlert && (
          <AlertDialog
            handleYes={() => {
              HideRejectAlertDialog();
              reject
                ? StatusForFourthStep.includes(model.status)
                  ? rejectFourthStep()
                  : rejectSecondStep()
                : StatusForFourthStep.includes(model.status)
                  ? incompleteFourthStep()
                  : incompleteSecondStep();
            }}
            handleNo={() => {
              HideRejectAlertDialog();
            }}
            show={showRejectAlert}
            contentText={
              reject
                ? "Unos će biti trajno odbijen."
                : incomplete
                  ? "Unos će biti označen kao nepotpun."
                  : "Unos će biti označen kao neispravno potpisan."
            }
            handleYesText={t("Accept")}
            className={"btn btn-accept"}
          ></AlertDialog>
        )}
      </MainContainer>
    </>
  );
};

export default CreateInput;
