class InputModel {
  constructor(
    id = 0,
    companyIdentifier = "",
    companyName = "",
    companyAddressAndCity = "",
    contractNumber = "",
    subjectMatterOfTheContract = "",
    responsiblePersonFirstName = "",
    responsiblePersonLastName = "",
    responsiblePersonEmail = "",
    responsiblePersonPhoneNumber = "",
    replacementResponsiblePersonFirstName = "",
    replacementResponsiblePersonLastName = "",
    replacementResponsiblePersonEmail = "",
    replacementResponsiblePersonPhoneNumber = "",
    bzrResponsiblePersonFirstName = "",
    bzrResponsiblePersonLastName = "",
    bzrResponsiblePersonPhoneNumber = "",
    bzrResponsiblePersonEmail = "",
    bzrWithContract = true,
    createdByBzrOfficer = true,
    status = 0,
    inputWorkflowPeople = [],
    inputWorkflowEquipments = [],
    fkSapcontract = 0
  ) {
    this.id = id;
    this.companyIdentifier = companyIdentifier;
    this.companyName = companyName;
    this.companyAddressAndCity = companyAddressAndCity;
    this.contractNumber = contractNumber;
    this.subjectMatterOfTheContract = subjectMatterOfTheContract;
    this.responsiblePersonFirstName = responsiblePersonFirstName;
    this.responsiblePersonLastName = responsiblePersonLastName;
    this.responsiblePersonEmail = responsiblePersonEmail;
    this.responsiblePersonPhoneNumber = responsiblePersonPhoneNumber;
    this.replacementResponsiblePersonFirstName =
      replacementResponsiblePersonFirstName;
    this.replacementResponsiblePersonLastName =
      replacementResponsiblePersonLastName;
    this.replacementResponsiblePersonEmail = replacementResponsiblePersonEmail;
    this.replacementResponsiblePersonPhoneNumber =
      replacementResponsiblePersonPhoneNumber;
    this.bzrResponsiblePersonFirstName = bzrResponsiblePersonFirstName;
    this.bzrResponsiblePersonLastName = bzrResponsiblePersonLastName;
    this.bzrResponsiblePersonPhoneNumber = bzrResponsiblePersonPhoneNumber;
    this.bzrResponsiblePersonEmail = bzrResponsiblePersonEmail;
    this.bzrWithContract = bzrWithContract;
    this.createdByBzrOfficer = createdByBzrOfficer;
    this.status = status;
    this.inputWorkflowPeople = inputWorkflowPeople;
    this.inputWorkflowEquipments = inputWorkflowEquipments;
    this.fkSapcontract = fkSapcontract;
  }
}

class InputAcceptRejectModel {
  constructor(id = null, comment = "", sapContractId = null) {
    this.id = id;
    this.comment = comment;
    this.sapContractId = sapContractId;
  }
}

class InputSearchModel {
  constructor(ledgerNumber = "", companyName = "", contractNumber = "", statuses = null) {
    this.ledgerNumber = ledgerNumber;
    this.companyName = companyName;
    this.contractNumber = contractNumber;
    this.statuses = statuses;
  }
}

class InputPersonModel {
  constructor(
    companyIdentifier = "",
    companyName = "",
    fkSapcontract = null,
    fkSapcontractNavigation = undefined,
    bzrWithContract = false,
    firstName = "",
    lastName = "",
    employeePosition = "",
    identityCardNumber = "",
    identityCardPlace = "",
    identityPassportNumber = "",
    identityPassportPlace = "",
    foreigner = false,
    status = 0
  ) {
    this.companyIdentifier = companyIdentifier;
    this.companyName = companyName;
    this.fkSapcontract = fkSapcontract;
    this.fkSapcontractNavigation = fkSapcontractNavigation;
    this.bzrWithContract = bzrWithContract;
    this.firstName = firstName;
    this.lastName = lastName;
    this.employeePosition = employeePosition;
    this.identityCardNumber = identityCardNumber;
    this.identityCardPlace = identityCardPlace;
    this.identityPassportNumber = identityPassportNumber;
    this.identityPassportPlace = identityPassportPlace;
    this.foreigner = foreigner;
    this.status = status;
  }
}

class InputEquipmentModel {
  constructor(
    companyIdentifier = "",
    companyName = "",
    fkSapcontract = null,
    fkSapcontractNavigation = undefined,
    bzrWithContract = true,
    name = "",
    model = "",
    type = "",
    identificationNumber = "",
    status = 0
  ) {
    this.companyIdentifier = companyIdentifier;
    this.companyName = companyName;
    this.fkSapcontract = fkSapcontract;
    this.fkSapcontractNavigation = fkSapcontractNavigation;
    this.bzrWithContract = bzrWithContract;
    this.name = name;
    this.model = model;
    this.type = type;
    this.identificationNumber = identificationNumber;
    this.status = status;
  }
}

class PersonSearchModel {
  constructor(
    companyIdentifier = "",
    companyName = "",
    firstName = "",
    lastName = "",
    employeePosition = "",
    identityCardNumber = "",
    identityCardPlace = "",
    identityPassportNumber = "",
    identityPassportPlace = "",
    fkSapcontract = null,
    bzrWithContract = undefined,
    withSapContract = true
  ) {
    this.companyIdentifier = companyIdentifier;
    this.companyName = companyName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.employeePosition = employeePosition;
    this.identityCardNumber = identityCardNumber;
    this.identityCardPlace = identityCardPlace;
    this.identityPassportNumber = identityPassportNumber;
    this.identityPassportPlace = identityPassportPlace;
    this.fkSapcontract = fkSapcontract;
    this.bzrWithContract = bzrWithContract;
    this.withSapContract = withSapContract;
  }
}

class EquipmentSearchModel {
  constructor(
    companyIdentifier = "",
    companyName = "",
    name = "",
    model = "",
    type = "",
    identificationNumber = "",
    bzrWithContract = undefined,
    withSapContract = true
  ) {
    this.companyIdentifier = companyIdentifier;
    this.companyName = companyName;
    this.name = name;
    this.model = model;
    this.type = type;
    this.identificationNumber = identificationNumber;
    this.bzrWithContract = bzrWithContract;
    this.withSapContract = withSapContract;
  }
}

export {
  InputModel,
  InputAcceptRejectModel,
  InputSearchModel,
  InputPersonModel,
  InputEquipmentModel,
  PersonSearchModel,
  EquipmentSearchModel,
};
